.contato__form{
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    column-gap: 20px;

    .form-group{
        margin-bottom: 10px;

        &:nth-child(1) {
            grid-column: 1 / 3;
        }

        &.segura__group__btn{
            grid-column: 1 / 3;
            border: 2px solid #727376;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .orcamento__input{
                border: none;
            }
        }
    }
}
.enviar__botao{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 50px;
    background-color: #727376;
    color: #fff;
    font-family: 'poppinssemibold';
    font-size: 14px;
    line-height: 14px;
    border: none;
    padding-top: 2px;
    transition: all 0.3s linear;

    &:hover{
        background-color: #ed3237;
    }

    &:focus{
        outline: none;
    }
}
@include media-breakpoint-down (sm) {
    .contato__form{
        display: block;
    }
}
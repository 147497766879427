.rodape__conteudo{
    background-image: url('../images/rodape__bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 48px 0px 22px 0px;

    .titulo__secao{
        color: #fff;
        text-align: center;
        margin-bottom: 30px;

        &:before{
            background-color: #fff;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
.rodape__creditos{
    background-color: #727376;
    color: #fff;
}
.email__contato__header{
    display: flex;
    
    .email__titulo{
        margin-right: 5px;
    }
}
.rodape__input{
    display: block;
    width: 100%;
    height: auto;
    border: 2px solid #fff;
    padding: 16px 18px 17px 18px;
    font-family: 'poppinsitalic';
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    background-color: transparent;
    resize: none;

    &::placeholder{
        color: #fff;
    }

    &:focus{
        outline: none;
    }
}
.rodape__form{
    display: grid;
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    column-gap: 10px;

    .form-group{
        margin-bottom: 10px;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(5) {
            grid-column: 1 / 3;
        }
    }
    .rodape__form__btn{
        grid-column: 1 / 3;
    }
}
.rodape__mapa{
    .embed-responsive{
        padding-bottom: 66%;
    }
}
.btn__enviar{
    width: 160px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -40px;
    margin-left: auto;
    margin-right: auto;
    border: none;
    background-color: #727376;
    color: #fff;
    font-family: 'poppinssemibold';
    font-size: 14px;
    line-height: 16px;
    transition: all 0.3s linear;

    &:hover{
        background-color: #fff;
        color: #727376;
        text-decoration: none;
    }
}
.rodape__row{
    padding-bottom: 44px;
    border-bottom: 1px solid #fff;
    margin-bottom: 46px;
}
.proposta__titulo{
    font-family: 'poppinsbold';
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    margin-bottom: 10px;
}
.proposta__btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 252px;
    height: 50px;
    background-color: #727376;
    color: #fff;
    font-family: 'poppinssemibold';
    font-size: 14px;
    line-height: 14px;
    transition: all 0.3s linear;
    margin-bottom: 16px;

    &:hover{
        background-color: #fff;
        color: #727376;
        text-decoration: none;
    }
}
.proposta__sociais{
    display: flex;
    align-items: center;

    .sociais__span{
        font-family: 'poppinssemibold';
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        margin-right: 6px;
    }
    nav{
        display: flex;

        a{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border: 2px solid #fff;
            border-radius: 50%;
            color: #fff;
            transition: all 0.3s linear;
            font-size: 16px;
            line-height: 16px;
            margin: 0px 1px;

            &:hover{
                background-color: #727376;
                border: 2px solid #727376;
                text-decoration: none;
            }
        }
    }
}
.rodape__titulo{
    font-family: 'poppinsbold';
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 24px;
    color: #fff;
}
.rodape__nav{
    color: #fff;

    a{
        display: block;
        margin-bottom: 5px;
    }
}
.rodape__contato__box{
    display: flex;
    align-items: flex-start;
    margin-bottom: 14px;
}
.col-lg-6{
    &.row{
        padding: 0px;
    }
}
.rodape__contato__conteudo{
    font-family: 'poppinsregular';
    font-size: 13px;
    line-height: 17px;
    color: #fff;
}
.rodape__contato__endereco{
	text-align: justify;
}
.rodape__contato__mail{
	text-align: center;
	max-width: 136px;
	width: 100%;
	margin-right: auto;
	margin-left: 0px;

	a{
		&:hover{
			text-decoration: none;

			span{
				text-decoration: underline;
			}
		}
	}
}
.rodape__contato__icone{
    color: #fff;
    margin-right: 5px;
}
.creditos{
    span{
        font-family: 'poppinsbold';
    }
}
.rodape__creditos{
    padding: 20px 0px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
}
.gv8__box{
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
        text-decoration: none;
    }

    svg{
        margin-left: 5px;
    }
}
.newsletter__desc{
    font-size: 13px;
    line-height: 16px;
    color: #fff;
    text-align: justify;
    margin-bottom: 12px;
}
.news__form{
    display: flex;
    align-items: center;
    background-color: #fff;
    padding-right: 5px;
    border-radius: 0px 18px 0px 0px;
}
.news__input{
    display: block;
    width: 100%;
    border: none;
    height: auto;
    padding: 16px 18px 12px 18px;
    font-size: 13px;
    line-height: 16px;
    color: #959595;

    &:focus{
        outline: none;
    }

    &::placeholder{
        color: #959595;
    }
}
.news__button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #727376;
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    border: none;
    min-width: 36px;
}
@include media-breakpoint-down (md) {
    .rodape__form{
        margin-bottom: 40px;
    }
    .proposta__titulo,
    .rodape__titulo{
        text-align: center;
    }
    .proposta__btn{
        margin-left: auto;
        margin-right: auto;
    }
    .proposta__sociais{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .sociais__span{
            margin-bottom: 5px;
        }
        nav{
            margin-bottom: 40px;
        }
    }
    .rodape__nav{
        text-align: center;
        margin-bottom: 40px;
    }
    .rodape__contato__box{
        flex-direction: column;
        align-items: center;
        margin-bottom: 25px;
        text-align: center;

        .rodape__contato__icone{
            margin-right: 0px;
            margin-bottom: 3px;
        }
    }
    .col-lg-6{
        &.row{
            margin-left: 0px;
            margin-right: 0px;
        }
    }
}

#quem__carousel{
    position: relative;
    max-width: 538px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .carousel-controls{

        .banner__prev,
        .banner__next{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border: 2px solid #727376;
            border-radius: 50%;
            margin: 0px 3px;
            transition: all 0.3s linear;
            color: #727376;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &:hover{
                background-color: #727376;
                border: 2px solid #727376;
                text-decoration: none;
                color: #fff;
            }
        }
        .banner__prev{
            left: -15px;
        }
        .banner__next{
            right: -15px;
        }
    }
}
.produtos__interna__desc{
    text-align: justify;
    margin-bottom: 24px;
}
.produtos__interna{
    .produtos__categoria{
        box-shadow: 0px 0px 3px rgba(#000, 0.4);
    }
    .col-sm-6{
        margin-bottom: 20px;
    }
}
.segura__produto__btn{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.produto__interna__btns{
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
	flex-wrap: wrap;

    .conteudo__btn{
        max-width: calc(50% - 5px);

        &:nth-child(2){
            margin-right: 5px;
        }
        &:nth-child(3) {
            margin-left: 5px;
        }

		&.grande{
			max-width: 100%;
			width: 100%;
			margin-bottom: 10px;
		}
    }
}
@include media-breakpoint-down (md) {
    #quem__carousel{
        margin-bottom: 30px;
    }
}
@include media-breakpoint-down (xs) {
    .produto__interna__btns{
        flex-direction: column;
        align-items: center;

        .conteudo__btn{
            max-width: 100%;
            margin-bottom: 10px;

            &:nth-child(1){
                margin-right: 0px;
            }
            &:nth-child(2) {
                margin-left: 0px;
            }
        }
    }
}

#banner__carousel{
    max-width: 1920px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    text-align:center;

    .carousel-inner{
        // max-width: 1920px;
        // width: 160%;
        // position: relative;
        // left: 50%;
        // transform: translateX(-50%);
    }
    .carousel-controls{
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 50px;
        width: 100%;

        @include media-breakpoint-down(md){
            position:relative;
            padding-top:20px; 
        }

        a{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border: 2px solid #fff;
            border-radius: 50%;
            margin: 0px 3px;
            transition: all 0.3s linear;
            color: #fff;

            @include media-breakpoint-down(md){
                position:relative;
                border-color:#000;
                color:#000;
            }

            &:hover{
                background-color: #727376;
                border: 2px solid #727376;
                text-decoration: none;
            }
        }
    }
}
.banner__prev{
    span{
        margin-right: 2px;
    }
}
.banner__next{
    span{
        margin-left: 2px;
    }
}
.info__circulo{
	--perimetro: 251px;
	position: relative;
	max-width: 254px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	display: block;

	&.active{
		circle.animated{
			animation: strokeAnimada 2s linear forwards;
		}
	}

	circle.animated{
		stroke-dasharray: calc(var(--perimetro) * 1px);
		stroke-dashoffset: calc(var(--perimetro) * 1px);
		transition: all 1s linear;
		transform-origin: center center;
		transform: rotate(-90deg);
	}
}
@keyframes strokeAnimada {
	0%{
		stroke-dashoffset: calc(var(--perimetro) * 1px);
	}
	100%{
		stroke-dashoffset: calc(
      		(var(--perimetro) - (var(--perimetro) * var(--total))) * 1px
    	);
	}
}
.info__conteudo{
	position: absolute;
	top: 50%;
	left: 50%;
    transform: translate(-50%, -50%);
    max-width: 210px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    font-family: 'poppinsmedium';
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000;
}
.info__numero{
	font-family: 'poppinsbold';
	font-size: 35px;
	line-height: 35px;
	color: #000;
	margin-bottom: 2px;
	text-align: center;
}
.titulo__secao{
    font-family: 'poppinsbold';
    font-size: 30px;
    line-height: 32px;
    color: #000000;
    padding-top: 34px;
    position: relative;
    margin-bottom: 10px;

    &:before{
       content: '';
       position: absolute;
       top: 0px;
       left: 0px;
       width: 160px;
       height: 8px;
       background-color: #ed3237;
    }
}
.equipe{
    padding-top: 52px;
    padding-bottom: 42px;
}
.info__circulos{
    padding-top: 50px;
}
.info__texto{
    --linhas: 3;
}
.equipe__desc{
    text-align: justify;
    margin-bottom: 24px;
}
.conteudo__btn{
    display: inline-flex;
    width: 255px;
    max-width: 100%;
    height: 65px;
    background-color: #727376;
    color: #fff;
    font-family: 'poppinssemibold';
    font-size: 14px;
    line-height: 14px;
    align-items: center;
    justify-content: center;
    transition: all 0.3s linear;
    border: none;

    &:focus{
        outline: none;
    }

    &:hover{
        background-color: #ed3237;
        text-decoration: none;
        color: #fff;
    }
}
.produtos{
    padding: 52px 0px 40px 0px;
    position: relative;

    .titulo__secao{
        color: #fff;
    }

    &:after{
        content: '';
        position: absolute;
        z-index: -2;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background-color: #727376;
    }
    &:before{
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background-image: url('../images/detalhe__produto.png');
        background-size: 1920px 1604px;
        background-repeat: no-repeat;
        background-position: center bottom;
        opacity: 0.8;
    }

    // .owl-carousel{

    //     .owl-dots{
    //         display:flex;
    //         align-items:center;
    //         max-width:610px;
    //         margin-left:auto;
    //         margin-right:auto;

    //         @include media-breakpoint-down(md){
    //             padding-top:20px;
    //         }

    //         @include media-breakpoint-up(lg){
    //             position:absolute;
    //             bottom:9px;
    //             left:50%;
    //             transform:translateX(-50%);
    //             width:100%;
    //         }

    //         & > *{
    //             margin:0 5px;
    //             flex-grow:1;

    //             &:first-child{
    //                 margin-left:0;
    //             }

    //             &:last-child{
    //                 margin-right:0;
    //             }

    //             & > *{
    //                 display:block;
    //                 font-size:10px;
    //                 width:100%;
    //                 height:1em;
    //                 background-color:#d3d5dc;
    //                 transition:background-color 0.6s linear;
    //             }

    //             &.active{

    //                 & > *{
    //                     background-color:#ed3237;
    //                 }

    //             }

    //         }

    //     }

    // }

}
.produtos__header{
    margin-bottom: 32px;

    .row{
        align-items: flex-start;
    }
}
.produtos__section__desc{
    text-align: justify;
    color: #fff;
}
.produtos{

    .categorias__listagem{
        overflow-y: auto;
        direction: rtl;

        // width */
        &::-webkit-scrollbar {
          width: 15px;
        }

        // Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1; 
        }
         
        // Handle */
        &::-webkit-scrollbar-thumb {
          background: #ed3237; 
        }

        // Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: darken(#ed3237,10%); 
        }
    }

}
.categorias__listagem{
    display: flex;
    flex-direction: column;
    max-height: 256px;
    position: relative;

    .categoria__item{
        display: block;
        padding: 16px 24px;
        border-bottom: 1px solid #ededed;
        border-left: 1px solid #ededed;
        border-right: 1px solid #ededed;
        background-color: #fff;
        font-size: 16px;
        line-height: 18px;
        transition: all 0.3s linear;
        position: relative;
        z-index: 100;

        &:after{
            width: 7px;
            height: calc(100% + 2px);
            content: '';
            position: absolute;
            background-color: #ed3237;
            right: -1px;
            top: -1px;
            transition: all 0.3s linear;
            opacity: 0;
        }

        &:hover{
            background-color: #ededed;
            color: #000000;
            text-decoration: none;

            &:after{
                opacity: 0.5;
            }
        }

        &.categoria__item--active{
            background-color: #ededed;

            &:after{
                opacity: 1;
            }
        }
    }
}
.categoria__todos{
    display: block;
    padding: 18px 24px;
    color: #ed3237;
    font-family: 'poppinssemibold';
    font-size: 16px;
    line-height: 18px;
    background-color: #fff;
    transition: all 0.3s linear;

    &:hover{
        background-color: #727376;
        color: #fff;
        text-decoration: none;
    }
}
.interna{

    .categorias__listagem{
        max-height:100%;
    }

}
.produto__card{
    display: block;
    width: 100%;
    max-width: 350px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    overflow: hidden;
    transition: all 0.3s linear;

    &:hover{
        text-decoration: none;
        border-radius: 0px 56px 0px 0px;

        &:after{
            opacity: 1;
        }
        .produto__nome{
            opacity: 1;
        }
    }

    &:after{
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        background-image: url('../images/produto__card__detalhe.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        z-index: 20;
        opacity: 0;
        transition: all 0.3s linear;
    }
    .produto__nome{
        position: absolute;
        bottom: 36px;
        left: 0px;
        max-width: 100%;
        width: 100%;
        padding: 0px 15px;
        font-family: 'poppinssemibold';
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #fff;
        z-index: 30;
        opacity: 0;
        transition: all 0.3s linear;
    }
}
.seta__right{
    width: 44px;
    height: 28px;
    background-image: url('../images/seta__right.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block;
}
.seta__left{
    width: 44px;
    height: 28px;
    background-image: url('../images/seta__left.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: block;
}
.mobile__circulos{
	display: none;
}
#produtos__owl.owl-carousel{
    .owl-nav{
        display: flex;
        justify-content: space-between;
        padding-top: 40px;
        margin-left: auto;
        margin-right: auto;
        position: relative;

        button{
            &:focus{
                outline: none;
            }
        }

        &:after{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 9px;
            height: 10px;
            width: calc(100% - 120px);
            content: '';
            background-color: #d3d5dc;

            // @include media-breakpoint-up(lg){
            //     position: absolute;
            //     left: 50%;
            //     transform: translateX(-50%);
            //     bottom: 9px;
            //     height: 10px;
            //     width: calc(100% - 120px);
            //     content: '';
            //     background-color: #d3d5dc;
            // }
        }
    }
}
#banner__carousel{
    .carousel-controls{
        bottom: 10%;
    }
}
#clientes__owl.owl-carousel{
    .owl-item{
        padding: 5px;
    }
    .owl-nav{
        button.owl-prev,
        button.owl-next{
            position: absolute;
            width: 40px;
            height: 40px;
            border: 2px solid #727376;
            color: #727376;
            font-size: 16px;
            line-height: 16px;
            border-radius: 50%;
            top: 50%;
            transform: translateY(-50%);
            transition: all 0.3s linear;

            &:focus{
                outline: none;
            }
            &:hover{
                background-color: #727376;
                color: #fff;
            }
        }
        button.owl-prev{
            left: -20px;

            i{
                margin-right: 2px;
            }
        }
        button.owl-next{
            right: -20px;

            i{
                margin-left: 2px;
            }
        }
    }
    .owl-dots{
        display: none;
    }
}
.cliente__card{
    display: flex;
	max-width: 210px;
	align-items: center;
	justify-content: center;
    height: 120px;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(#000, 0.5);
    transition: all 0.3s linear;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;

    &:hover{
        background-color: #ed3237;
        box-shadow: none;
        border-radius: 0px 50px 0px 0px;
	}
	.cliente__imagem{
		img{
			width: auto !important;
		}
	}
}
.clientes{
    padding: 46px 0px 54px 0px;

    .titulo__secao{
        text-align: center;
        margin-bottom: 42px;

        &:before{
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
@include media-breakpoint-only (lg) {
    .info__conteudo{
        max-width: 170px;
    }
}
@include media-breakpoint-down (md) {
    .segura__equipe__btn{
        margin-bottom: 40px;
    }
    .info__circulos{
        .col-lg-3{
            margin-bottom: 20px;
        }
    }
    .produto__card{
        &:after{
            opacity: 1;
        }
        .produto__nome{
            opacity: 1;
        }
    }
    .produtos__categoria{
        margin-bottom: 40px;
    }
    .mobile__logo{
        max-width: 90px;
    }
}
@include media-breakpoint-down (sm) {
    .titulo__secao{
        font-size: 22px;
        line-height: 26px;
    }
}
@include media-breakpoint-down (xs) {
    #clientes__owl.owl-carousel{
        .owl-nav{
            button.owl-prev{
                left: -15px;

                i{
                    margin-right: 2px;
                }
            }
            button.owl-next{
                right: -15px;

                i{
                    margin-left: 2px;
                }
            }
        }
	}
	.desktop__circulos{
		display: none;
	}
	.mobile__circulos{
		display: block;
	}
	#mobile__circulos{
		.carousel-item{
			.info__card{
				.info__circulo{
					circle.animated{
						stroke-dashoffset: calc(
							(var(--perimetro) - (var(--perimetro) * var(--total))) * 1px
						);
					}
				}
			}
		}
		.carousel-controls{
			display: flex;
			justify-content: center;
			position: relative;
			bottom: 0px;
			margin: 10px 0px;
			width: 100%;

			a{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 40px;
				height: 40px;
				border: 2px solid #000;
				border-radius: 50%;
				margin: 0px 3px;
				transition: all 0.3s linear;
				color: #000;

				&:hover{
					background-color: #727376;
					border: 2px solid #727376;
					color: #fff;
					text-decoration: none;
				}
			}
		}
	}
}

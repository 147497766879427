@font-face {
    font-family: 'poppinsmedium';
    src: url('../fonts/popins-medium/poppins-medium-webfont.eot');
    src: url('../fonts/popins-medium/poppins-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/popins-medium/poppins-medium-webfont.woff2') format('woff2'),
         url('../fonts/popins-medium/poppins-medium-webfont.woff') format('woff'),
         url('../fonts/popins-medium/poppins-medium-webfont.ttf') format('truetype'),
         url('../fonts/popins-medium/poppins-medium-webfont.svg#poppinsmedium') format('svg');
    font-weight: normal;
    font-style: normal;

}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 30, 2019 */



@font-face {
    font-family: 'poppinsbold';
    src: url('../fonts/poppins-bold/poppins-bold-webfont.eot');
    src: url('../fonts/poppins-bold/poppins-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/poppins-bold/poppins-bold-webfont.woff2') format('woff2'),
         url('../fonts/poppins-bold/poppins-bold-webfont.woff') format('woff'),
         url('../fonts/poppins-bold/poppins-bold-webfont.ttf') format('truetype'),
         url('../fonts/poppins-bold/poppins-bold-webfont.svg#poppinsbold') format('svg');
    font-weight: normal;
    font-style: normal;

}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 30, 2019 */



@font-face {
    font-family: 'poppinsitalic';
    src: url('../fonts/poppins-italic/poppins-italic-webfont.eot');
    src: url('../fonts/poppins-italic/poppins-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/poppins-italic/poppins-italic-webfont.woff2') format('woff2'),
         url('../fonts/poppins-italic/poppins-italic-webfont.woff') format('woff'),
         url('../fonts/poppins-italic/poppins-italic-webfont.ttf') format('truetype'),
         url('../fonts/poppins-italic/poppins-italic-webfont.svg#poppinsitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 30, 2019 */



@font-face {
    font-family: 'poppinsregular';
    src: url('../fonts/poppins-regular/poppins-regular-webfont.eot');
    src: url('../fonts/poppins-regular/poppins-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/poppins-regular/poppins-regular-webfont.woff2') format('woff2'),
         url('../fonts/poppins-regular/poppins-regular-webfont.woff') format('woff'),
         url('../fonts/poppins-regular/poppins-regular-webfont.ttf') format('truetype'),
         url('../fonts/poppins-regular/poppins-regular-webfont.svg#poppinsregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'poppinssemibold';
    src: url('../fonts/poppins-semibold/poppins-semibold-webfont.eot');
    src: url('../fonts/poppins-semibold/poppins-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/poppins-semibold/poppins-semibold-webfont.woff2') format('woff2'),
         url('../fonts/poppins-semibold/poppins-semibold-webfont.woff') format('woff'),
         url('../fonts/poppins-semibold/poppins-semibold-webfont.ttf') format('truetype'),
         url('../fonts/poppins-semibold/poppins-semibold-webfont.svg#poppinssemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}
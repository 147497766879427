.orcamento__input{
    display: block;
    width: 100%;
    height: auto;
    padding: 18px 28px 16px 28px;
    border: 2px solid #727376;
    color: #979797;
    font-family: 'poppinsitalic';
    resize: none;

    &:focus{
        outline: none;
    }

    &::placeholder{
        color: #979797;
    }
}
.orcamento__form{
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    column-gap: 20px;

    .form-group{
        margin-bottom: 10px;

        &:nth-child(1){
            grid-column: 1 / 3;
        }
    }
    .segura__orcamento__btn{
        grid-column: 1 / 3;
        display: flex;
        justify-content: center;
        padding-top: 15px;

        .conteudo__btn{
            &:nth-child(1){
                margin-right: 10px;
                max-width: calc(50% - 10px);
            }
            &:nth-child(2){
                margin-left: 10px;
                max-width: calc(50% - 10px);
            }
        }
    }
}
@include media-breakpoint-down (sm) {
    .orcamento__form{
        display: block;
    }
}
@include media-breakpoint-down (xs) {
    .orcamento__form{
        .segura__orcamento__btn{
            flex-direction: column;
            align-items: center;

            .conteudo__btn{
                margin-bottom: 10px;
                
                &:nth-child(1){
                    margin-right: 0px;
                    max-width: 100%;
                }
                &:nth-child(2){
                    margin-left: 0px;
                    max-width: 100%;
                }
            }
        }
    }
}
.breadcrump{
    padding: 39px 0px 14px 0px;
    background-color: #e4e4e4;
    font-family: 'poppinsregular';
    font-size: 14px;
    line-height: 18px;
    color: #000000;

    .container{
        display: flex;
        justify-content: flex-end;

        .breadcrump__titulo{
            font-family: 'poppinssemibold';
            color: #ed3237;
            margin-left: 4px;
        }
    }
}
.interna{
    padding: 90px 0px 46px 0px;

    .container{
        position: relative;

        &:after{
            content: '';
            position: absolute;
            width: 162px;
            height: 8px;
            background-color: #ed3237;
            top: -40px;
            left: 15px;
        }
    }
}
.titulo__interna{
    font-family: 'poppinsbold';
    font-size: 30px;
    line-height: 34px;
    color: #000;
    margin-bottom: 10px;
}
.segura__voltar__btn{
    display: flex;
    justify-content: center;
    padding-top: 15px;
}
@include media-breakpoint-down (md) {
    .breadcrump{
        padding: 16px 0px 14px 0px;
    }
}
.main__vindo{
    span{
        font-family: 'poppinsbold';
    }
}
.quotation__container{
	#USD{
		font-family: 'poppinsregular !important';
	}
}
.contato__box{
    display: flex;
    align-items: center;
}
.main__menu{
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
}
.contato__titulo{
    font-family: 'poppinssemibold';
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}
.pesquisa__form{
    display: flex;
    margin-left: 22px;
    background-color: #727376;
    position: relative;

    &:before{
        width: 22px;
        height: 100%;
        background-color: #727376;
        clip-path: polygon(0 100%, 100% 100%, 100% 0);
        content: '';
        position: absolute;
        top: 0px;
        left: -21px;
    }

    label{
        margin-bottom: 0px;
        width: 100%;
        display: block;
    }
}
.main__pesquisa{
    min-width: 286px;
}
.pesquisa__input{
    display: block;
    width: 100%;
    font-family: 'poppinsitalic';
    font-size: 14px;
    line-height: 18px;
    padding: 16px 10px;
    background-color: #727376;
    color: rgba(#fff, 0.5);
    border: none;

    &:focus{
        outline: none;
    }

    &::placeholder{
        color: rgba(#fff, 0.5);
    }
}
.pesquisa__btn{
    border: none;
    background-color: #727376;
    padding: 0px 20px;
    color: #fff;
    font-size: 24px;
    line-height: 24px;
}
.drop__menu{
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
}
.drop__menu__link{
    display: block;
    width: 100%;
    padding: 7px 10px;
    text-align: center;
}
.quotation__container{
    #USD{
		background-color: #ed3237 !important;
		font-family: 'poppinsregular' !important;
    }
}
@include media-breakpoint-up (lg) {
    .topo__mobile{
        display: none;
    }
    .main__logo{
        display: none;
    }
    .main__cima{
        overflow: hidden;

        .container{
            display: flex;
            justify-content: space-between;
        }
    }
    .cima__faixa{
        display: flex;
        background-color: #ed3237;
        color: #fff;
        padding: 8px 6px 10px 8px;
        align-items: center;
        margin-left: 30px;
        position: relative;

        &:after{
            width: 100vw;
            content: '';
            position: absolute;
            z-index: -1;
            height: 100%;
            background-color: #ed3237;
            top: 0px;
            left: 30px;
        }

        &:before{
            width: 30px;
            content: '';
            position: absolute;
            background-color: #ed3237;
            top: 0px;
            left: -29px;
            clip-path: polygon(0 0, 100% 100%, 100% 0);
            height: 100%;
        }
    }
    .cima__contato{
        display: flex;
    }
    .main__vindo{
        margin-right: 56px;
    }
    .main__sociais{
        display: flex;
        font-family: 'poppinsbold';
        align-items: center;

        nav{
            display: flex;
            margin-left: 8px;

            a{
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #fff;
                color: #fff;
                border-radius: 50%;
                width: 30px;
                font-size: 16px;
                line-height: 16px;
                height: 30px;
                margin: 0px 2px;
                transition: all 0.3s linear;

                &:hover{
                    text-decoration: none;
                    background-color: #727376;
                    border: 2px solid #727376;
                }
            }
        }
    }
    .linguagem__nav{
        margin: 0px 14px;
        padding: 0px 14px;
        display: flex;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;

        a{
            display: block;
            margin: 0px 2px;
        }
    }
    .dolar__box{
        display: flex;
        align-items: center;
        padding-right: 10px;

        .dolar__cotacao{
            margin-left: 3px;
        }
    }
    .cima__contato{
        display: flex;
        justify-content: space-between;
    }
    .contato__box{
        padding: 28px 0px;

        .contato__icone{
            margin-right: 7px;
        }
    }
    .cima__logo{
        padding: 10px 0px;
    }
    .main__baixo{
        // margin-bottom: -26px;

        .container{
            display: flex;
            justify-content: space-between;
            background-color: #ed3237;
            color: #fff;
            padding: 0px 0px 0px 15px;
        }
    }
    .pesquisa__mobile{
        display: none;
    }
    .main__menu{
        display: flex;

        .menu__item{
            button{
                display: none;
            }
            &.menu__item--active{
                .menu__link{
                    font-family: 'poppinsbold';
                }
            }
        }

        .menu__link{
            display: block;
            padding: 18px 10px;
            font-size: 14px;
            line-height: 14px;

            &:hover{
                font-family: 'poppinsbold';
                text-decoration: none;
            }
        }
    }
    .drop__menu{
        position: absolute;
        left: 0px;
        top: 100%;
        display: none;
        width: 240px;
        background-color: #ed3237;
        z-index: 1000;
        padding: 0px 15px 10px 15px;
    }
    .menu__item{
        position: relative;

        &:hover{
            .drop__menu{
                display: block;
            }
        }
    }
    .drop__menu__link{
        &:hover{
            color: #fff;
            text-decoration: none;
            font-family: 'poppinsbold';
        }
    }
}
@include media-breakpoint-only (lg) {
    .cima__logo{
        margin-right: 40px;
    }
    .main__sociais{
        span{
            display: none;
        }
        a{
            span{
                display: block;
            }
        }
    }
    .main__vindo{
        margin-right: 10px;
    }
    .main__pesquisa{
        min-width: 200px;
    }
}
@include media-breakpoint-down (md) {
    .topo__mobile{
        padding-top: 10px;
        padding-bottom: 10px;

        .container{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .mbl__toggler{
        border: none;
        background-color: transparent;
        font-size: 24px;
        line-height: 24px;

        &:focus{
            outline: none;
        }
    }
    .topo__main{
        position: fixed;
        z-index: 1080;
        left: -250px;
        top: 0px;
        width: 250px;
        height: 100%;
        transition: all 0.5s linear;
        background-color: #fff;
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        .container{
            padding: 0px;
        }

        .main__baixo{
            order: 1;
        }
        .main__cima{
            order: 2;
        }
        .cima__logo{
            display: none;
        }
        .main__logo{
            text-align: center;
            padding: 10px;
        }

        .main__pesquisa{
            display: none;
        }

        &.shown{
            left: 0px;
        }
    }
    .bg__menu{
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, 0.6);
        animation: fadeIn 0.5s linear;
        z-index: 1050;

        &.hide{
            animation: fadeOut 0.5s linear;
        }
    }
    .menu__link{
        display: block;
        width: 100%;
        padding: 10px 10px;
        font-family: 'poppinsmedium';
        font-size: 15px;
        line-height: 15px;
        background-color: #ed3237;
        color: #fff;

        &:hover{
            text-decoration: none;
            color: #fff;
        }
    }
    .menu__item{
        &.menu__item--active{
            .menu__link{
                font-family: 'poppinsbold';
                background-color: #727376;
            }
        }
    }
    .drop__controls{
        width: 100%;
        position: relative;

        button{
            position: absolute;
            width: 20px;
            height: 20px;
            border: none;
            top: 49%;
            transform: translateY(-50%);
            right: 10px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.3s linear;

            &[aria-expanded="true"] {
                transform: translateY(-50%) rotate(90deg);
            }

            &:focus{
                outline: none;
            }

            &:after{
                content: '\f078';
                display: inline-block;
                font-weight: 900;
                font-family: "Font Awesome 5 Free";
                font-size: 12px;
                line-height: 12px;
                color: #000;
                padding-top: 2px;
            }
        }
    }
    .main__vindo{
        padding: 5px 10px;
        background-color: #727376;
        color: #fff;
        text-align: center;
        border-top: 2px solid #fff;
    }
    .main__sociais{
        display: flex;
        flex-direction: column;
        text-align: center;
        background-color: #ed3237;
        color: #fff;
        font-family: 'poppinsbold';
        padding: 10px 0px;

        nav{
            display: flex;
            justify-content: center;
            margin-top: 4px;

            a{
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #fff;
                color: #fff;
                border-radius: 50%;
                width: 30px;
                font-size: 16px;
                line-height: 16px;
                height: 30px;
                margin: 0px 2px;
                transition: all 0.3s linear;

                &:hover{
                    text-decoration: none;
                    background-color: #727376;
                    border: 2px solid #727376;
                }
            }
        }
    }
    .linguagem__nav{
        display: flex;
        padding: 10px;
        justify-content: center;
        background-color: #727376;

        a{
            display: block;
            margin: 0px 5px;
            border: 2px solid #fff;
            border-radius: 50%;
        }
    }
    .dolar__box{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ed3237;
        color: #fff;
        padding: 10px;

        .dolar__cotacao{
            margin-left: 3px;
        }
    }
    .contato__box{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px 10px;
        text-align: center;

        .contato__icone{
            margin-bottom: 10px;
        }
    }
   .pesquisa__mobile{
       position: fixed;
       z-index: 1080;
       left: 50%;
       transform: translateX(-50%);
       top: -200px;
       max-width: 800px;
       width: 100%;
       padding: 0px 20px;
       transition: all 0.5s linear;

       .pesquisa__form,
       .pesquisa__btn,
       .pesquisa__input{
           background-color: #ed3237;
       }
       .pesquisa__input{
           color: #fff;

           &::placeholder{
               color: #fff;
           }
       }
       .pesquisa__form{
           &:before{
               background-color: #ed3237;
           }
       }

       &.shown{
           top: 50px;
       }
   }
}

.noticia__card{
    display: block;
    max-width: 350px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    &:hover{
        text-decoration: none;

        .produto__card{
            border-radius: 0px 56px 0px 0px;
            
            &:after{
                opacity: 1;
            }
            .produto__nome{
                opacity: 1;
            }
        }
    }

    .produto__card{
        margin-bottom: 15px;
    }
}
.noticia__card__desc{
    text-align: justify;
}